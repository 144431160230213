import React, { createContext, useState, useContext } from 'react';

const TasteProfileContext = createContext();

export const useTasteProfile = () => useContext(TasteProfileContext);

export const TasteProfileProvider = ({ children }) => {
    const [tasteProfile, setTasteProfile] = useState({ selectedOptions: [], priceRange: '' });

    return (
        <TasteProfileContext.Provider value={{ tasteProfile, setTasteProfile }}>
            {children}
        </TasteProfileContext.Provider>
    );
};
