import React from 'react';
import './Forms.css';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../UI/Button/Button';
import FormLayout from '../UI/Card/FormLayout';
import { useTasteProfile } from './TasteProfileContext';

const CustomCheckbox = ({ field, option }) => {
    return (
        <button
            type="button"
            className={`optionButton ${field.value.includes(option) ? 'selected' : ''}`}
            onClick={() => {
                if (field.value.includes(option)) {
                    const nextValue = field.value.filter(value => value !== option);
                    field.onChange({ target: { value: nextValue, name: field.name } });
                } else {
                    const nextValue = field.value.concat(option);
                    field.onChange({ target: { value: nextValue, name: field.name } });
                }
            }}
        >
            {option}
        </button>
    );
};

const TasteProfileForm = () => {
    const { setTasteProfile } = useTasteProfile();
    const navigate = useNavigate();

    const initialValues = {
        selectedOptions: [],
        priceRange: '',   
        city: '',
        state: '',
        zip: '',
    };

    // const handleSubmit = (values, { setSubmitting, resetForm }) => {
    //     setTasteProfile({
    //         selectedOptions: values.selectedOptions,
    //         priceRange: values.priceRange,
    //         city: values.city,
    //         state: values.state,
    //         zip: values.zip
    //     });
        
    //     navigate('/Discover');
    //     setSubmitting(false);
    //     resetForm(initialValues)
    // };

    

    const handleSubmit = (values, { setSubmitting, resetForm }) => {

        const categoriesString = values.selectedOptions ? values.selectedOptions.join(',') : '';
        
        setTasteProfile({
            location: `${values.city}, ${values.state} ${values.zip}`,
            categories: categoriesString,
            price: parseInt(values.priceRange),
            sort_by: 'best_match',
            limit: 9,
            offset: Math.floor(Math.random() * 150),
        });
        
        navigate('/Discover');
        setSubmitting(false);
        resetForm(initialValues)
    };

    const validate = (values) => {
        let errors = {};
        if (!values.priceRange) {
            errors.priceRange = "Required";
        }
        if (!values.selectedOptions || values.selectedOptions.length < 3) {
            errors.selectedOptions = "Please select at least 3 options";
        }
        if (!values.city) {
            errors.city = "City is required";
        }
        if (!values.state) {
            errors.state = "State is required";
        }
        if (!values.zip) {
            errors.zip = "Zip code is required";
        } else if (!/^\d{5}$/.test(values.zip)) {
            errors.zip = "Invalid zip code";
        }
        return errors;
    };

    const options = ["Vietnamese", "Chinese", "Japanese", "Korean", "Thai", "Indian", "Mexican", "Italian", "American", "Mediterranean", "French", "Greek", "Spanish", "Middle Eastern", "African", "Caribbean", "Latin American", "Eastern European", "German", "British", "Irish", "Scandinavian", "Turkish", "Moroccan", "Ethiopian", "BBQ", "Vegan", "Pizza", "Burgers", "Seafood", "Sushi", "Steakhouse", "Vegetarian", "Gluten-Free", "Cafe", "Deli", "Bakery", "Ice Cream", "Desserts", "Barbecue", "Food Trucks", "Food Stalls", "Buffets", "Fine Dining", "Brunch", "Food Court", "Pub Food", "Wine Bars", "Cocktail Bars", "Breweries", "Craft Beer", "Sports Bars", "Juice Bars", "Smoothies", "Coffee Shops", "Tea Rooms", "Bubble Tea", "Breakfast", "Lunch", "Dinner", "Late-Night Eats", "Family-Friendly", "Fast Food", "Casual Dining", "Outdoor Seating", "Romantic", "Group Dining", "Pet-Friendly", "Vegetarian-Friendly", "Vegan-Friendly", "Gluten-Free Options", "Halal", "Kosher", "Farm-to-Table", "Organic", "Locally Sourced", "Food Festivals"];
    
    return (
        <FormLayout>
            <div className='formBG'>
                <div className='formHeader'>
                    <h1>Taste Profile</h1>
                </div>
                <div className='form'>
                    <Formik 
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={handleSubmit}
                    >
                        {({ isValid }) => (
                            <Form>
                                <label>Pick at least 3 cuisines you like:</label>
                                <div className="optionsContainer">
                                    {options.map(option => (
                                        <div key={option}>  
                                            <Field name="selectedOptions" value={option} component={CustomCheckbox} option={option} />
                                            <ErrorMessage 
                                                name="selectedOptions"
                                                component="div"
                                                className='errorForm'
                                            />
                                        </div>
                                    ))}
                                </div>
                                <label>
                                    Price Range:
                                    <Field name="priceRange" as="select" className='formInput'>
                                        <option value="">Select a price range</option>
                                        <option value="1">$</option>
                                        <option value="2">$$</option>
                                        <option value="3">$$$</option>
                                        <option value="4">$$$$</option>
                                    </Field>
                                    <ErrorMessage
                                        name="priceRange"
                                        component="div"
                                        className='errorForm'
                                    />
                                </label>
                                <label>
                                    Location:
                                    <Field name="city" type="text" placeholder="City" className='formInput' />
                                    <ErrorMessage
                                        name="city"
                                        component="div"
                                        className='errorForm'
                                    />
                                    <Field name="state" as="select" className='formInput'>
                                        <option value="">Select a state</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Conneticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KA">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MI">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersy</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennesse</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virgina</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virgina</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </Field>
                                    <ErrorMessage
                                        name="state"
                                        component="div"
                                        className='errorForm'
                                    />
                                    <Field name="zip" type="text" placeholder="Zip Code" className='formInput' />
                                    <ErrorMessage
                                        name="zip"
                                        component="div"
                                        className='errorForm'
                                    />
                                </label>
                                <div className='tasteProfileButton'>
                                    <Button disabled={!isValid} type="submit">Submit</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </FormLayout>
    );
};

export default TasteProfileForm;
