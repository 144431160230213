import React, { useState, useEffect } from 'react';
import ResturantCard from "./UI/Card/ResturantCard";
import AlertSnackbar from './Forms/AlertSnackbar';
import Rating from '@mui/material/Rating'
import Box from '@mui/material/Box'
import Button from './UI/Button/Button';
import LoadingScreen from './LoadingScreen';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { useTasteProfile } from './Forms/TasteProfileContext'

const Discover = () => {

    const [businesses, setBusinesses] = useState([]);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [favoriteStatus, setFavoriteStatus] = useState({})
    const [successMessage, setSuccessMessage] = useState('');
    const [nextPage, setNextPage] = useState(0);
    const { tasteProfile } = useTasteProfile();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        // console.log('Api fetch initiated');
        // console.log("Taste Profile:", tasteProfile);
        // console.log('useEffect called');
        setIsLoading(true)

        const fetchData = async () => {
        
            const offset = nextPage * tasteProfile.limit;

            const yelpEndpoint = `${baseURL}/explore?location=${encodeURIComponent(tasteProfile.location)}&categories=${encodeURIComponent(tasteProfile.categories)}&price=${encodeURIComponent(tasteProfile.price)}&sort_by=${encodeURIComponent(tasteProfile.sort_by)}&limit=${encodeURIComponent(tasteProfile.limit)}&offset=${offset}`;
            // const yelpEndpoint = `${baseURL}/explore?location=${encodeURIComponent(tasteProfile.location)}&categories=${encodeURIComponent(tasteProfile.categories)}&price=${encodeURIComponent(tasteProfile.price)}&sort_by=${encodeURIComponent(tasteProfile.sort_by)}&limit=${encodeURIComponent(tasteProfile.limit)}&offset=${encodeURIComponent(tasteProfile.offset)}`;
                
            // console.log("Making API request to:", yelpEndpoint);

            const yelpOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                cache: 'no-cache', 
            };

            try {
                const yelpResponse = await fetch(yelpEndpoint, yelpOptions);
                
                if (yelpResponse.ok) {
                    const responseData = await yelpResponse.json();
                    
                    if (responseData && responseData.businesses) {
                        setBusinesses(responseData.businesses); // Set the businesses state
        
                        // Retrieve current favorites from local storage
                        const userFavoriteRestaurants = JSON.parse(localStorage.getItem('favorites')) || {};
                        
                        // Create a map of favorite status for each business
                        const favoriteStatus = {}; 
                        responseData.businesses.forEach(business => {
                            favoriteStatus[business.id] = userFavoriteRestaurants.hasOwnProperty(business.id);
                        });
                        
                        setFavoriteStatus(favoriteStatus); // Set the favorite status state
                    }
                    
                    setIsLoading(false);
                } else {
                    console.log('Error: Server responded with status code', yelpResponse.status);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Exception occurred during fetch:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [nextPage, tasteProfile]);

    if (isLoading) {
        return <LoadingScreen />;  // Render loading screen when data is being fetched
    }

    const handleFavoriteButton = async (business) => {
        // Check if the restaurant is already in favorites
        if (favoriteStatus[business.id]) {
            setSuccessMessage('');
            setTimeout(() => {
                setSuccessMessage('Already in Favorites!');
            }, 1);
            return;
        }
    
        // Prepare the restaurant object to save in favorites
        const favoriteRestaurant = {
            id: business.id,
            name: business.name,
            image_url: business.image_url,
            rating: business.rating,
            address: business.location.display_address,
            phone: business.display_phone,
            url: business.url,
            categories: business.categories.map(category => category.title),
        };
    
        // Retrieve the current favorites from local storage, or initialize an empty object if none exist
        const currentFavorites = JSON.parse(localStorage.getItem('favorites')) || {};
    
        // Add the new favorite restaurant
        currentFavorites[business.id] = favoriteRestaurant;
    
        // Save the updated favorites back to local storage
        localStorage.setItem('favorites', JSON.stringify(currentFavorites));
    
        // Update the favorite status in state
        setFavoriteStatus({
            ...favoriteStatus,
            [business.id]: true,
        });
    
        setSuccessMessage('');
        setTimeout(() => {
            setSuccessMessage('Added to Favorites!');
        }, 1);
    
        // Optionally, update the list of businesses if needed
        setBusinesses(businesses.filter(item => item.id !== business.id));
    };
    

    const handleDeleteButton = (business) => {
        setBusinesses(businesses.filter(item => item.id !== business.id))
    }

    const handleNewApiCall = async () => {
        setSuccessMessage(''); 
        setNextPage(prevPage => prevPage + 1);

    }


    return (
        <>
        <AlertSnackbar successMessage={successMessage} />
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'flex-start'
        }}>
        
            {businesses.map((business) => 
                <ResturantCard 
                    key={business.id} 
                    business={business} 
                    handleDeleteButton={handleDeleteButton}
                    handleFavoriteButton={handleFavoriteButton} 
                    avatar= {business.name.charAt(0)}
                    image = {business.image_url}
                    subheader = {business.categories[0].title}
                    icon={<FavoriteRoundedIcon/>}
                    showInfo={false}
                >
                    
                    <p>
                        <a href={`https://maps.google.com/maps?q=${encodeURIComponent(business.location.display_address.join(' '))}`} target="_blank">
                            {business.location.display_address.join(', ')}
                        </a>
                    </p>

                    <Rating name="read-only" value={business.rating} readOnly />
                    <p><a href={`tel:${business.display_phone}`}>{business.display_phone}</a></p>
                </ResturantCard>
            )}
        </Box>
        <Box sx={{
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 2,
        }}>
            <Button 
                onClick={handleNewApiCall} 
                sx={{ 
                    backgroundColor: '#FF6616',
                    borderRadius: '20px',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'darkorange', 
                    },
                    '&:active': {
                    backgroundColor: 'red',
                    },
                    width : '300px',
                }}
            >
                Discover More
            </Button>
        </Box>
        </>
    );
}


export default Discover;
